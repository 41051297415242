import React from "react"

import { withFeaturedArticles } from "./withFeatureArticles"
import { FeaturedArticlesWrapper } from "./FeaturedArticlesStyle"
import { Card } from "../../Articles/Card/Card"
import { FeaturedSlider } from "../../Slider/FeaturedSlider/FeaturedSlider"

export const FeaturedArticles = withFeaturedArticles(({ title, articles, additionalViewAllNewsText, url }) => (
  <FeaturedArticlesWrapper>
    <FeaturedSlider id={"article"} title={title} items={articles} callToActionText={additionalViewAllNewsText} callToActionUrl={url} Card={Card} layout={"article"} />
  </FeaturedArticlesWrapper>
))
