import React from "react"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Icon } from "../../Icon/Icon"
import { Wrapper, Prev, Next } from "./HeroCarouselStyles"
import { withHeroCarousel } from "./withHeroCarousel"
import { Hero } from "../Hero/Hero"
import { HeroFullWidthImage } from "../HeroFullWidthImage/HeroFullWidthImage"

SwiperCore.use([Autoplay])

export const HeroCarousel = withHeroCarousel(
  ({ items, ratio, autoplayDelay, handlePromoClick }): JSX.Element => (
    <Wrapper>
      <Swiper
        autoplay={items?.length > 1 ? { delay: autoplayDelay } : false}
        loop={items?.length > 1}
        navigation={{
          prevEl: "#hero-prev",
          nextEl: "#hero-next",
        }}
        slidesPerView={1.05}
        breakpoints={{
          1024: {
            slidesPerView: 1,
          },
        }}
      >
        {items?.map(card => (
          <SwiperSlide key={card?._key}>
            {card?._type === "sectionHeroFullWidthImage" ? (
              <HeroFullWidthImage {...card} ratio={ratio} handlePromoClick={handlePromoClick} />
            ) : (
              <Hero {...card} ratio={ratio} handlePromoClick={handlePromoClick} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {items?.length > 1 ? (
        <Prev id={"hero-prev"} title={"previous image"}>
          <Icon name={"chevronLeft"} width={16} height={22} title={`Arrow left`} />
        </Prev>
      ) : null}
      {items?.length > 1 ? (
        <Next id={"hero-next"} title={"next image"}>
          <Icon name={"chevronRight"} width={16} height={22} title={`Arrow right`} />
        </Next>
      ) : null}
    </Wrapper>
  )
)

export default HeroCarousel
