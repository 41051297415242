import React, { lazy } from "react"
import { Link } from "gatsby"

import { withArticle } from "./withArticle"
import { Grid, Grid6, Wrapper, BreadCrumb, Crumb, ImageWrapper, Details, Divider, Title, PublishedAt } from "./ArticleStyles"
import { Image } from "../Image/Image"
import { RichText } from "../RichText/RichText"
import { Author } from "./Author/Author"
import { FeaturedArticles } from "./FeaturedArticles/FeaturedArticles"
import { useCore } from "../../hooks/useCore"
import { ArticleSection } from "./ArticleSection/ArticleSection"
import { HeroCarousel } from "../Sections/HeroCarousel/HeroCarousel"

const componentRootPath = `${__dirname}components/Article/`

export const Article = withArticle(({ article, title, author, publishedAt, image, content, additionalArticlesTitle, url, settingShowRelated }) => {
  // debug
  // article = {
  //   ...article,
  //   sections: [article.sections[0]]
  // }

  return (
    <>
      {article.hideHeader || (
        <Wrapper>
          <BreadCrumb>
            <Crumb as={Link} to={url}>
              {additionalArticlesTitle}
            </Crumb>
            &nbsp;/&nbsp;
            <Crumb>{title}</Crumb>
          </BreadCrumb>
          <ImageWrapper>
            <Image image={image} ratio={"126/79"} />
          </ImageWrapper>
          <Details>
            {author ? <Author author={author} /> : null}
            <PublishedAt>{publishedAt}</PublishedAt>
          </Details>
          <Divider />
          <Title>{title}</Title>
          <RichText>{content}</RichText>
        </Wrapper>
      )}

      <Grid>
        {article?.sections?.map?.((section, index) => {
          if (section._type.includes("ArticleSection")) {
            return <ArticleSection key={index} {...section}></ArticleSection>
          } else if (section._type.includes("Hero")) {
            return (
              <Grid6 key={index}>
                <HeroCarousel {...section}></HeroCarousel>
              </Grid6>
            )
          }
        })}
      </Grid>
      {settingShowRelated ? (
        <>
          <Divider />
          <FeaturedArticles />
        </>
      ) : null}
    </>
  )
})
