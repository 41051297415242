import React from "react"

import { Span } from "./ArticleSectionStyles"
import { ImageVideo } from "../../ImageVideo/ImageVideo"
import { RichText } from "../../Sections/RichText/RichText"
import Hero from "../../Sections/Hero/Hero"
import ImageContent from "../../Sections/ImageContent/ImageContent"
import HeroFullWidthImage from "../../Sections/HeroFullWidthImage/HeroFullWidthImage"

export const ArticleSection = articleSection => {
  return (
    <Span
      style={{
        backgroundColor: articleSection?.backgroundColour?.hex,
        outline: "1px solid " + articleSection?.backgroundColour?.hex,
      }}
      {...articleSection}
      title={null}
      id={articleSection?.title + articleSection?._key}
    >
      {articleSection?.content?.map?.((section, index) => {
        if (section._type.includes("imageVideoResponsive")) {
          return <ImageVideo key={index} imageVideo={section}></ImageVideo>
        } else if (section._type == "sectionHero") {
          return (
            <div key={index} className={"ArticleSectionHero"} style={{ width: `100%` }}>
              <Hero {...section} />
            </div>
          )
        } else if (section._type == "sectionHeroFullWidthImage") {
          return (
            <div key={index} className={"ArticleSectionHeroFullWidthImage"} style={{ width: `100%` }}>
              <HeroFullWidthImage {...section} />
            </div>
          )
        } else if (section._type == "sectionImageContent") {
          return (
            <div key={index} className={"ArticleSectionImageContent"} style={{ width: `100%` }}>
              <ImageContent {...section} />
            </div>
          )
        } else if (section._type == "sectionRichText") {
          return (
            <div key={index} className={"ArticleSectionRTE"}>
              <RichText {...section}></RichText>
            </div>
          )
        }
      })}
    </Span>
  )
}
