import React from "react"

import { withImageContent } from "./withImageContent"
import { Wrapper, CardWrapper } from "./ImageContentStyles"
import { TextButtonsGrid } from "../../TextButtonsGrid/TextButtonsGrid"
import { ImageGridCard } from "./Card/ImageGridCard"
import { HeroCard } from "../HeroFullWidthImage/Card/HeroCard"
import { Card } from "./Card/Card"
import { useRoutes } from "../../../hooks/useRoutes"

export const ImageContent = withImageContent(({ items, layout, ratio, itemSpacing }) => {
  const { linkResolver } = useRoutes()

  return (
    <Wrapper layout={layout} spacing={itemSpacing}>
      {items?.map(item => (
        <CardWrapper key={item?.key} layout={layout} background={item?.backgroundColor?.hex} spacing={itemSpacing}>
          {item?._type === "objectSectionImageContent" ? (
            <Card key={item._key} title={item.title} link={linkResolver(item.link)} image={item.image} description={item.content} ratio={ratio} />
          ) : item?._type === "imageCardHero" ? (
            <HeroCard key={item._key} card={item} ratio={ratio} />
          ) : item?._type === "imageCardNormal" ? (
            <ImageGridCard key={item._key} card={item} ratio={ratio} />
          ) : item?._type === "pureBackgroundColorCard" ? (
            <TextButtonsGrid key={item._key} content={item?.content} />
          ) : null}
        </CardWrapper>
      ))}
    </Wrapper>
  )
})

export default ImageContent
