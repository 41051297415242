import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useImage } from "../../../hooks/useImage"

export const withAuthor = Component => ({ name = "Author", author }) => {
  const { getFluidImage } = useImage()

  const { article } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_ARTICLE_AUTHOR {
      article: sanityTemplateArticle {
        additionalAuthorText
      }
    }
  `)

  const { additionalAuthorText } = article || {}

  const { image: originalImage, name: authorName } = author
  const image = getFluidImage(originalImage, { maxWidth: 60 })

  Component.displayName = name
  return <Component name={authorName} image={image} additionalAuthorText={additionalAuthorText} />
}
