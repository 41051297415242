import React from "react"
import { useAnalytics } from "../../../hooks/useAnalytics"
import { RichText as Wrapper } from "../../RichText/RichText"

export const RichText = ({ content, analytics = {} }) => {
  const { trackPromoImpression, trackPromoClick, VisibilitySensor } = useAnalytics()
  return (
    <VisibilitySensor onChange={visible => visible && trackPromoImpression({ ...analytics })}>
      <div onClick={() => trackPromoClick({ ...analytics })}>
        <Wrapper>{content}</Wrapper>
      </div>
    </VisibilitySensor>
  )
}
export default RichText
