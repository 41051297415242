import tw from "twin.macro"

export const Wrapper = tw.article`
  max-w-67-8 mx-auto px-2-4
`

export const Grid = tw.div`
  grid grid-cols-6
`
export const Grid6 = tw.div`
  col-span-6
`

export const BreadCrumb = tw.div`
  mt-7 md:mt-8-4 mb-2-4 md:mb-4-2 inline-block
`

export const Crumb = tw.p`
  text-16 leading-1.31 inline
`

export const ImageWrapper = tw.div`
  w-full mb-2-4 md:mb-4
`

export const Details = tw.div`
  pb-2-4 md:pb-4 flex flex-col-reverse justify-start md:flex-row md:justify-between
`

export const Divider = tw.div`
  mb-3 md:mb-5-6 max-w-lg mx-auto
`

export const Title = tw.h1`
  text-20 md:text-35 leading-1.25 md:leading-1.28 tracking-2 md:tracking-3 font-bold mb-3 md:mb-5-6 uppercase
`

export const PublishedAt = tw.p`
  text-14 leading-1.78 text-grey mb-2-4
`
