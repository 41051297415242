import React from "react"
import { graphql } from "gatsby"

import { Article as Template } from "../components/Article/Article"

export const query = graphql`
  query($id: String!) {
    article: sanityArticle(_id: { eq: $id }) {
      ...GatsbyArticleFragment
      hideHeader
    }
    template: sanityTemplateArticle {
      settingShowRelated
      additionalArticlesTitle
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
