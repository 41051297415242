import React from "react"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

import { useImage } from "../../hooks/useImage"
import { useApp } from "../../hooks/useApp"

dayjs.extend(LocalizedFormat)

export const withArticle = Component => ({ name = "Article", article, template }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { getFluidImage } = useImage()

  const { attributes, content, image: originalImage, title } = article

  const { additionalArticlesTitle, settingShowRelated } = template

  const { author, publishedAt } = attributes || {}

  const formattedPublishedAt = dayjs(publishedAt).format("LL")
  const image = getFluidImage(originalImage, { maxWidth: 630 })

  const url = routes.ARTICLE

  Component.displayName = name
  return (
    <Component
      author={author}
      article={article}
      publishedAt={formattedPublishedAt}
      content={content}
      image={image}
      title={title}
      additionalArticlesTitle={additionalArticlesTitle}
      url={url}
      settingShowRelated={settingShowRelated}
    />
  )
}
