import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useFeaturedArticles } from "../../../hooks/useArticles"
import { useApp } from "../../../hooks/useApp"

export const withFeaturedArticles = Component => ({ name = "FeaturedArticles" }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { articles } = useFeaturedArticles()

  const { article } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_ARTICLE_FEATURED {
      article: sanityTemplateArticle {
        additionalViewAllNewsText
        additionalContinueText
      }
    }
  `)

  const { additionalViewAllNewsText, additionalContinueText } = article || {}

  const items = articles?.edges?.map(({ node }) => node)
  const url = routes.ARTICLE

  Component.displayName = name
  return <Component title={additionalContinueText} articles={items} additionalViewAllNewsText={additionalViewAllNewsText} url={url} />
}
