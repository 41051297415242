import React from "react"
import { ImageVideo } from "../../../ImageVideo/ImageVideo"
import { TextButtonsGrid } from "../../../TextButtonsGrid/TextButtonsGrid"
import { withImageGridCard } from "./withImageGridCard"

export const ImageGridCard = withImageGridCard(({ ratio, image, content, additionalContent }) => (
  <div>
    {Boolean(content) && <TextButtonsGrid content={content} />}
    <ImageVideo imageVideo={image} ratio={ratio} />
    {Boolean(additionalContent) && <TextButtonsGrid content={additionalContent} />}
  </div>
))
