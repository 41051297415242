import tw, { styled } from "twin.macro"

export const Span = styled.div`
  ${props => {
    if (props.alignCenter) {
      return tw`flex justify-center items-center w-full h-full`
    }
  }}
  ${props => {
    if (props.paddingMobile === 0) {
      return tw`p-0`
    } else if (props.paddingMobile === 1) {
      return tw`p-1`
    } else if (props.paddingMobile === 2) {
      return tw`p-2`
    } else if (props.paddingMobile === 3) {
      return tw`p-3`
    } else if (props.paddingMobile === 4) {
      return tw`p-4`
    } else if (props.paddingMobile === 5) {
      return tw`p-5`
    } else if (props.paddingMobile === 6) {
      return tw`p-6`
    } else if (props.paddingMobile === 7) {
      return tw`p-7`
    } else if (props.paddingMobile === 8) {
      return tw`p-8`
    } else if (props.paddingMobile === 9) {
      return tw`p-9`
    } else if (props.paddingMobile === 10) {
      return tw`p-10`
    } else if (props.paddingMobile === 11) {
      return tw`p-11`
    } else if (props.paddingMobile === 12) {
      return tw`p-12`
    }
  }}
  ${props => {
    if (props.paddingDesktop === 0) {
      return tw`lg:p-0`
    } else if (props.paddingDesktop === 1) {
      return tw`lg:p-1`
    } else if (props.paddingDesktop === 2) {
      return tw`lg:p-2`
    } else if (props.paddingDesktop === 3) {
      return tw`lg:p-3`
    } else if (props.paddingDesktop === 4) {
      return tw`lg:p-4`
    } else if (props.paddingDesktop === 5) {
      return tw`lg:p-5`
    } else if (props.paddingDesktop === 6) {
      return tw`lg:p-6`
    } else if (props.paddingDesktop === 7) {
      return tw`lg:p-7`
    } else if (props.paddingDesktop === 8) {
      return tw`lg:p-8`
    } else if (props.paddingDesktop === 9) {
      return tw`lg:p-9`
    } else if (props.paddingDesktop === 10) {
      return tw`lg:p-10`
    } else if (props.paddingDesktop === 11) {
      return tw`lg:p-11`
    } else if (props.paddingDesktop === 12) {
      return tw`lg:p-12`
    }
  }}
  ${props => {
    if (props.marginMobile === 0) {
      return tw`m-0`
    } else if (props.marginMobile === 1) {
      return tw`m-1`
    } else if (props.marginMobile === 2) {
      return tw`m-2`
    } else if (props.marginMobile === 3) {
      return tw`m-3`
    } else if (props.marginMobile === 4) {
      return tw`m-4`
    } else if (props.marginMobile === 5) {
      return tw`m-5`
    } else if (props.marginMobile === 6) {
      return tw`m-6`
    } else if (props.marginMobile === 7) {
      return tw`m-7`
    } else if (props.marginMobile === 8) {
      return tw`m-8`
    } else if (props.marginMobile === 9) {
      return tw`m-9`
    } else if (props.marginMobile === 10) {
      return tw`m-10`
    } else if (props.marginMobile === 11) {
      return tw`m-11`
    } else if (props.marginMobile === 12) {
      return tw`m-12`
    }
  }}
  ${props => {
    if (props.marginDesktop === 0) {
      return tw`lg:m-0`
    } else if (props.marginDesktop === 1) {
      return tw`lg:m-1`
    } else if (props.marginDesktop === 2) {
      return tw`lg:m-2`
    } else if (props.marginDesktop === 3) {
      return tw`lg:m-3`
    } else if (props.marginDesktop === 4) {
      return tw`lg:m-4`
    } else if (props.marginDesktop === 5) {
      return tw`lg:m-5`
    } else if (props.marginDesktop === 6) {
      return tw`lg:m-6`
    } else if (props.marginDesktop === 7) {
      return tw`lg:m-7`
    } else if (props.marginDesktop === 8) {
      return tw`lg:m-8`
    } else if (props.marginDesktop === 9) {
      return tw`lg:m-9`
    } else if (props.marginDesktop === 10) {
      return tw`lg:m-10`
    } else if (props.marginDesktop === 11) {
      return tw`lg:m-11`
    } else if (props.marginDesktop === 12) {
      return tw`lg:m-12`
    }
  }}
  ${props => {
    if (props.spanDesktop === 1) {
      return tw`lg:col-span-1 lg:flex`
    } else if (props.spanDesktop === 2) {
      return tw`lg:col-span-2 lg:flex`
    } else if (props.spanDesktop === 3) {
      return tw`lg:col-span-3 lg:flex`
    } else if (props.spanDesktop === 4) {
      return tw`lg:col-span-4 lg:flex`
    } else if (props.spanDesktop === 5) {
      return tw`lg:col-span-5 lg:flex`
    } else if (props.spanDesktop === 6) {
      return tw`lg:col-span-6 lg:flex`
    } else if (props.spanDesktop === 0) {
      return tw`lg:hidden`
    }
  }}
  ${props => {
    if (props.spanDesktopRow === 1) {
      return tw`lg:row-span-1`
    } else if (props.spanDesktopRow === 2) {
      return tw`lg:row-span-2`
    } else if (props.spanDesktopRow === 3) {
      return tw`lg:row-span-3`
    } else if (props.spanDesktopRow === 4) {
      return tw`lg:row-span-4`
    } else if (props.spanDesktopRow === 5) {
      return tw`lg:row-span-5`
    } else if (props.spanDesktopRow === 6) {
      return tw`lg:col-span-6`
    }
  }}
  ${props => {
    if (props.spanMobile === 1) {
      return tw`col-span-1`
    } else if (props.spanMobile === 2) {
      return tw`col-span-2`
    } else if (props.spanMobile === 3) {
      return tw`col-span-3`
    } else if (props.spanMobile === 4) {
      return tw`col-span-4`
    } else if (props.spanMobile === 5) {
      return tw`col-span-5`
    } else if (props.spanMobile === 6) {
      return tw`col-span-6`
    } else if (props.spanMobile === 0) {
      return tw`hidden`
    }
  }}
  
  ${props => {
    if (props.offsetDesktop === 0) {
      return tw`lg:col-start-1`
    } else if (props.offsetDesktop === 1) {
      return tw`lg:col-start-2`
    } else if (props.offsetDesktop === 2) {
      return tw`lg:col-start-3`
    } else if (props.offsetDesktop === 3) {
      return tw`lg:col-start-4`
    } else if (props.offsetDesktop === 4) {
      return tw`lg:col-start-5`
    } else if (props.offsetDesktop === 5) {
      return tw`lg:col-start-6`
    }
  }}
  ${props => {
    if (props.offsetMobile === 0) {
      return tw`col-start-1`
    } else if (props.offsetMobile === 1) {
      return tw`col-start-2`
    } else if (props.offsetMobile === 2) {
      return tw`col-start-3`
    } else if (props.offsetMobile === 3) {
      return tw`col-start-4`
    } else if (props.offsetMobile === 4) {
      return tw`col-start-5`
    } else if (props.offsetMobile === 5) {
      return tw`col-start-6`
    }
  }}
  ${props => {
    if (props.richTextColumns) {
      return `& .ArticleSectionRTE{column-count: ${props.richTextColumns}}`
    }
  }}
  ${props => {
    if (props.richTextColumnGap) {
      return `& .ArticleSectionRTE{column-gap: ${props.richTextColumnGap || 1}em}`
    }
  }}

`
