import tw from "twin.macro"

export const Wrapper = tw.div`flex-1 flex flex-row`

export const ImageWrapper = tw.div`border border-beige rounded-full max-w-6 w-full mr-1-8`

export const Content = tw.div``

export const Subtitle = tw.p`text-14 leading-1.78 text-grey mb-0-1`

export const Title = tw.p`text-18 leading-1.56`